import React from "react";
import Animation from "../HomeComponents/Animation";
import ImgSlider from "./ImgSlider";

export default function SliderContent({ ScSection_data }) {
  return (
    <section
      id={ScSection_data.id}
      className={`mt-5 slider-content   ${ScSection_data.background}`}
    >
      <div className="container mt-5">
        <div>
          <header className="section-header ">
            <h2>{ScSection_data.header}</h2>
            <div className="bar"></div>
          </header>
        </div>
        <div className="desc  ">
          <p>{ScSection_data.paragraph}</p>
        </div>
        <div className="row">
          <div className={`col-md-7   ${ScSection_data.mt_img} `}>
            <ImgSlider ImagesArray={ScSection_data.images} />
          </div>
          <div className={`col-md-5   ${ScSection_data.mt_div} `}>
            {ScSection_data.content.map((point, index) => (
              <div className="desc mt-4" key={index}>
                <h5>{point.head}</h5>
                <div className="row">
                  <div className="col-md-1">
                    {point.icon ? (
                      <i className="bi bi-check-circle-fill"></i>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-11">{point.points}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Animation />
    </section>
  );
}
